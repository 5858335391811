// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-chat-jsx": () => import("./../../../src/pages/chat.jsx" /* webpackChunkName: "component---src-pages-chat-jsx" */),
  "component---src-pages-contact-jsx": () => import("./../../../src/pages/contact.jsx" /* webpackChunkName: "component---src-pages-contact-jsx" */),
  "component---src-pages-demo-jsx": () => import("./../../../src/pages/demo.jsx" /* webpackChunkName: "component---src-pages-demo-jsx" */),
  "component---src-pages-preview-jsx": () => import("./../../../src/pages/preview.jsx" /* webpackChunkName: "component---src-pages-preview-jsx" */),
  "component---src-templates-author-jsx": () => import("./../../../src/templates/author.jsx" /* webpackChunkName: "component---src-templates-author-jsx" */),
  "component---src-templates-blog-jsx": () => import("./../../../src/templates/blog.jsx" /* webpackChunkName: "component---src-templates-blog-jsx" */),
  "component---src-templates-coeus-jsx": () => import("./../../../src/templates/coeus.jsx" /* webpackChunkName: "component---src-templates-coeus-jsx" */),
  "component---src-templates-file-jsx": () => import("./../../../src/templates/file.jsx" /* webpackChunkName: "component---src-templates-file-jsx" */),
  "component---src-templates-landing-page-jsx": () => import("./../../../src/templates/landing-page.jsx" /* webpackChunkName: "component---src-templates-landing-page-jsx" */),
  "component---src-templates-page-jsx": () => import("./../../../src/templates/page.jsx" /* webpackChunkName: "component---src-templates-page-jsx" */),
  "component---src-templates-post-jsx": () => import("./../../../src/templates/post.jsx" /* webpackChunkName: "component---src-templates-post-jsx" */),
  "component---src-templates-search-jsx": () => import("./../../../src/templates/search.jsx" /* webpackChunkName: "component---src-templates-search-jsx" */),
  "component---src-templates-services-jsx": () => import("./../../../src/templates/services.jsx" /* webpackChunkName: "component---src-templates-services-jsx" */),
  "component---src-templates-solutions-jsx": () => import("./../../../src/templates/solutions.jsx" /* webpackChunkName: "component---src-templates-solutions-jsx" */),
  "component---src-templates-team-jsx": () => import("./../../../src/templates/team.jsx" /* webpackChunkName: "component---src-templates-team-jsx" */)
}

